import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Popover from '@material-ui/core/Popover';
import Subscribe from '../components/subscribeHubspot'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            marginTop: '18%',
            height: 'auto'
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '0%',
            height: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '0%',
            height: 'auto'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '0%',
            height: 'auto'
        },
        height: '60vh',
        position: 'relative'
    },
    headerImg: {
        height: 'auto'
    },
    btn: {
        [theme.breakpoints.up('xs')]: {
            bottom: '20%',
            right: '14%',
            fontSize: '8px'
        },
        [theme.breakpoints.up('sm')]: {
            bottom: '16%',
            right: '15.5%',
            fontSize: '15px'
        },
        [theme.breakpoints.up('md')]: {
            bottom: '15%',
            right: '16.5%',
            fontSize: '18px'
        },
        [theme.breakpoints.up('lg')]: {
            bottom: '16%',
            right: '24%',
            fontSize: '16px'
        },
        position: 'absolute',
        backgroundColor: '#00cc64',
        color: 'black',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#16D5DF',
            color: 'black'
        },
        boxShadow: '0 3px 3px rgba(0, 0, 0, 0.4)',
    },
    headerText: {
        [theme.breakpoints.up('xs')]: {
            top: '38%',
            left: '8%',
            fontSize: '40px'
        },
        [theme.breakpoints.up('sm')]: {
            top: '45%',
            left: '13%',
            fontSize: '75px'
        },
        [theme.breakpoints.up('md')]: {
            top: '45%',
            left: '15%',
            fontSize: '85px'
        },
        [theme.breakpoints.up('lg')]: {
            top: '40%',
            left: '11.5%',
            fontSize: '50px'
        },
        position: 'absolute',
        color: 'white',
        fontWeight: 'bold',
    },
    'subscribe': {
        backgroundColor: 'transparent'
    }
}));

const Header = (pic, text) => {
    pic = Object.values(pic)
    let picture = Object.values(pic[0])
    picture = picture.join("")
    text = Object.values(pic[1])
    text = text.join("")
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div className={classes.paper}>
            <img src={picture} className={classes.headerImg} alt='' width='100%' height='20%' />
            <Button className={classes.btn} onClick={handleClick}>
                Subscribe
            </Button>

            <Typography variant="h2" className={classes.headerText} >
                {text}
            </Typography>
            <Popover
                classes={{ root: 'subscribe' }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <div>
                    <Subscribe />
                </div>
            </Popover>
        </div >
    )
}
export default Header