import React from "react"
import ResponsiveDrawer from "./homeSidebar"
import Scroll from '../components/scroll'
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 568,
            md: 760,
            lg: 960,
            xl: 1200,
        }
    }
});


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        [theme.breakpoints.up('xs')]: {
            marginLeft: '8%',
            paddingRight: '8%',
            marginTop: '-15%',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '8%',
            paddingRight: '8%',
            marginTop: '-8%',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '8%',
            paddingRight: '8%',
            marginTop: '-5%',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '5%',
            paddingRight: '5%',
            marginTop: '-4%',
        },
        flexGrow: 1,
        position: 'relative',
        // minHeight: '85vh',
    },
    boxHeader: {
        backgroundColor: 'grey'
    },
})
)

function Layout({ children }) {
    const classes = useStyles();
    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.content}
            >
                <Scroll showBelow={400} />
                <ResponsiveDrawer />
                <div
                    style={
                        {
                            minHeight: '50vh',
                        }
                    }>
                    {children}</div>
                <div
                    style={
                        {
                            textAlign: 'center',
                            justifyContent: 'center',
                            paddingBottom: '2%',
                        }
                    }>
                    © {new Date().getFullYear()} Julia Pottinger - All Rights Reserved
      </div>
            </div>
        </MuiThemeProvider>
    )

}

export default Layout;